import { Button, Container, Icon, Title } from '@/components'

import styles from './Contact.module.scss'

export const Contact = ({
  image,
  title,
  text,
  reverse,
  ctaProps,
  children
}) => {
  return (
    <section className={styles.contact}>
      <Container classname={styles.contact__wrapper}>
        {image}

        <div>
          <Title customClass={styles.contact__title} text={title} />

          {children}

          <div
            className={`${styles.contact__content} ${reverse && styles['contact__content--reverse']}`}
          >
            <p
              data-animate="fade-up"
              className={`${styles.contact__text} animate-init`}
            >
              {text}
            </p>

            {ctaProps && (
              <Button
                tagName="a"
                rel="noreferrer"
                id={ctaProps.id}
                href={ctaProps.url}
                variant={ctaProps.variant}
                className={`${styles.contact__button} animate-init`}
                data-animate="fade-up"
              >
                <Icon size="small" color="white" name={ctaProps.icon} />
                {ctaProps.text}
              </Button>
            )}
          </div>
        </div>
      </Container>
    </section>
  )
}
