'use client'

import { useState } from 'react'

import { Accordion } from '@/components'

export const Faq = ({ items = [] }) => {
  const [activeAccordionIndex, setActiveAccordionIndex] = useState()

  const handleTrigger = (accordionIndex) => {
    if (activeAccordionIndex === accordionIndex) {
      setActiveAccordionIndex(undefined)
      return
    }

    setActiveAccordionIndex(accordionIndex)
  }

  return (
    <div data-animate="fade-up" className="animate-init">
      {items.map(({ title, content }, index) => (
        <Accordion
          key={`${title}-${index}`}
          index={index}
          title={title}
          content={content}
          isActiveItem={activeAccordionIndex === index}
          handleTrigger={handleTrigger}
        />
      ))}
    </div>
  )
}
