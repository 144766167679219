import { Icon } from '@/components'

import { HOME_FAQ_DATA } from '../../constants'

import SelectSearch from '@/components/Form/Vanilla/Select'

import styles from './Filters.module.scss'

export const Filters = ({ activeFilterIndex, setActiveFilterIndex }) => {
  const mobileFilters = HOME_FAQ_DATA.map(({ title }, index) => ({
    label: title,
    value: index
  }))

  return (
    <>
      <div className={styles['filters--mobile']}>
        <SelectSearch
          id="faq-filter"
          name="faq-filter"
          label="Filtrar por"
          options={mobileFilters}
          defaultValue={HOME_FAQ_DATA[0].title}
          onChange={(_, { value }) => setActiveFilterIndex(value)}
        />
      </div>

      <div className={styles['filters--desktop']}>
        {HOME_FAQ_DATA.map(({ icon, title }, index) => {
          const isActive = activeFilterIndex === index

          return (
            <button
              key={`faq-filter-${index}`}
              id={`faq-filter-button--${index}`}
              className={`${styles.filters__button} ${isActive && styles['filters__button--active']}`}
              onClick={() => setActiveFilterIndex(index)}
            >
              <Icon
                name={icon}
                size="small"
                round="true"
                {...(isActive && {
                  color: 'white',
                  bgcolor: 'red'
                })}
              />
              {title}
            </button>
          )
        })}
      </div>
    </>
  )
}
