'use client'

import { Container, Proposal } from '@/components'

import styles from './styles.module.scss'

export const Hero = ({ variant, title, subtitle }) => {
  return (
    <>
      <section className={`${styles.hero} ${styles[`hero--${variant}`]}`}>
        <Container classname={styles.hero__wrapper}>
          <div className={styles.hero__textContent}>
            <h1 className={styles.hero__title}>{title}</h1>
            <h2 className={styles.hero__subtitle}>{subtitle}</h2>
          </div>

          <div className={styles['hero__proposal']}>
            <Proposal />
          </div>
        </Container>
      </section>
    </>
  )
}
