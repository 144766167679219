import { useState } from 'react'

import { HOME_FAQ_DATA, POSTPAID_FAQ_DATA } from './constants'

export const useHelp = ({ shouldRenderFilters }) => {
  const [activeFilterIndex, setActiveFilterIndex] = useState(0)

  const faqItems = shouldRenderFilters
    ? HOME_FAQ_DATA[activeFilterIndex].questions
    : POSTPAID_FAQ_DATA

  return {
    faqItems,
    activeFilterIndex,
    setActiveFilterIndex
  }
}
